import { FinancialInstitutionDisplayInfo } from '@api/model/financialInstitutionDisplayInfo';

import { DisableReason } from '@api/model/disableReason';
import { HttpErrorResponse } from '@angular/common/http';
import { OIDC_CODE, OIDC_ERROR } from '@core/constants/error-code';
import { BtnclickStatus } from '@core/directives/btn-with-spinner.directive';
import { ThemePalette } from '@angular/material/core';

export interface ErrorButton {
  label: string;
  color?: ThemePalette;
  url?: string;
  callBack?: any;
}

export interface ErrorOverlayConfig {
  hideCloseButton?: boolean;
}

export interface PbbError {
  heading?: string;
  title: string;
  message: string;
  icon?: string;
  image?: string;
  buttons?: Array<ErrorButton>;
  overlayConfig?: ErrorOverlayConfig;
}

export interface CoreState {
  financialInstitutions: LoaderState<FinancialInstitution[]>;
  headerConfig: HeaderConfig;
  footerConfig: FooterConfig;
  filteredAccountDisableReasons: Array<DisableReason>;
  error?: PbbError;
  merchantReferrer?: string;
  selectedFI?: FinancialInstitution;
  OIDCError?: OIDC_ERROR;
  OIDCCode?: OIDC_CODE;
  tempemail?: string;
  maxShippingAddress?: number;
  attachDeviceApi: LoaderState<BtnclickStatus | null>;
  updateDeviceApi: LoaderState<BtnclickStatus | null>;
  deviceId?: string;
}

export interface FinancialInstitution extends FinancialInstitutionDisplayInfo {}

export const initialState: CoreState = {
  financialInstitutions: {
    data: [],
    error: null,
    isLoading: false,
  },
  attachDeviceApi: {
    data: null,
    error: null,
    isLoading: false,
  },
  updateDeviceApi: {
    data: null,
    error: null,
    isLoading: false,
  },
  headerConfig: {
    enableBackButton: false,
  },
  footerConfig: {
    hasMerchantOpener: false,
  },
  filteredAccountDisableReasons: [],
};

export interface LoaderState<T> {
  data?: T;
  error?: string | null | Error | HttpErrorResponse;
  isLoading?: boolean;
}

export interface FooterConfig {
  hasMerchantOpener: boolean;
  merchantName?: string;
}

export interface HeaderConfig {
  enableBackButton: boolean;
  pageTitleKey?: string;
  title?: string;
  stepData?: {
    currentStep: number;
    currentStepTitle: string;
    totalSteps: number;
    isGuestCheckout?: boolean;
    showStepInfo?: boolean;
    continueToReview?: boolean;
    isRegistered?: boolean;
  };
  editPage?: boolean;
  showAmountChip?: boolean;
  hideLogo?: boolean;
  closeButton?: boolean;
  showMenu?: boolean;
  onPopState?: boolean;
  url?: string;
  showCenterLogo?: boolean;
  hideHeader?: boolean;
  hideFooter?: boolean;
  cookiePrompt?: boolean;
  showProfileIcon?: boolean;
  LanguageButton?: boolean;
  secondaryBackButton?: boolean;
  backUrl?: string;
}

export enum Notices {
  'REMOVE_ADDRESS' = 'SHIPPING_ADDRESS.REMOVE_ADDRESS',
  'UPDATE_ADDRESS' = 'SHIPPING_ADDRESS.UPDATE_ADDRESS',
  'ADD_ADDRESS' = 'SHIPPING_ADDRESS.ADD_ADDRESS',
  'DEFAULT_ADDRESS' = 'SHIPPING_ADDRESS.DEFAULT_ADDRESS',
  'REMOVE_DEVICE' = 'TRUSTED_DEVICE.REMOVE_DEVICE',
  'DISABLE_BIOMETRICS' = 'TRUSTED_DEVICE.DISABLE_BIOMETRICS',
  'UNLINK_FI' = 'PAYMENT_METHOD.UNLINK_FI',
  'DEFAULT_PAYMENT_METHOD' = 'PAYMENT_METHOD.DEFAULT_PAYMENT_METHOD',
  'WEBAUTH_PREP' = 'WEBAUTH_SIGNIN_PREP',
  'WEBAUTH_SIGNIN' = 'WEBAUTH_SIGNIN',
  'WEBAUTH_SIGNIN_RETRY' = 'WEBAUTH_SIGNIN_RETRY_LIMIT',
  'STEPUP_RETRY' = 'STEPUP_RETRY',
  'STEPUP_FAIL' = 'STEPUP_FAIL',
  'USER_REGISTRATION' = 'USER_REGISTRATION',
}

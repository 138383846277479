import { HttpErrorResponse } from '@angular/common/http';
import { ModelError } from '@api/model/modelError';
import { Notices, PbbError } from '@store/core/core.interface';
import { NOTICE_TYPE } from './notice-type.enum';

export enum ERROR_TYPE {
  FULL_PAGE = 'FULL PAGE',
  OVERLAY = 'OVERLAY',
}

export enum ERROR_CODE {
  SYSTEM = 'SYSTEM',
  SYSTEM_TRY_AGAIN = 'SYSTEM_TRY_AGAIN',
  REGISTER = 'REGISTER',
  SHIPPING = 'SHIPPING',
  SHIPPING_INVALID_REQUEST = 'SHIPPING_INVALID_REQUEST',
  SHIPPING_INVALID_POSTALCODE = 'SHIPPING_INVALID_POSTALCODE',
  SHIPPING_ADD = 'SHIPPING_ADD',
  SHIPPING_DEFAULT = 'SHIPPING_DEFAULT',
  GRANT_DENIED = 'DENIED',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  EMAIL_CONFIRMATION = 'EMAIL_CONFIRMATION',
  UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE',
  TOKENIZE_TIMEOUT = 'TOKENIZE_TIMEOUT',
  CHECKOUT_EMAIL_REG_STATUS = 'CHECKOUT_EMAIL_REG_STATUS',
  USER_PORTAL_EMAIL_REG_STATUS = 'USER_PORTAL_EMAIL_REG_STATUS',
  USER_PORTAL_REGISTER = 'USER_PORTAL_REGISTER',
  USER_PORTAL_COOKIE_DECLINE = 'USER_PORTAL_COOKIE_DECLINE',
  STEPUP_FAIL = 'STEPUP_FAIL',
}

export const MAP_SHIPPING_ERROR_CODE: { [key: string]: ERROR_CODE } = {
  '4008': ERROR_CODE.SHIPPING_INVALID_POSTALCODE,
};

export const ERROR_MAP: { [key: string]: PbbError } = {
  [ERROR_CODE.STEPUP_FAIL]: {
    title: 'COMMON.4507.TITLE',
    message: 'COMMON.4507.SUBTITLE',
  },
  [ERROR_CODE.SYSTEM]: {
    heading: 'IVS.ERROR.TITLE',
    title: 'COMMON.ERROR.SYSTEM.TITLE',
    message: 'COMMON.ERROR.SYSTEM.MESSAGE',
    image: 'assets/images/error.png',
  },
  [ERROR_CODE.SYSTEM_TRY_AGAIN]: {
    heading: 'IVS.ERROR.TITLE',
    title: 'COMMON.ERROR.SYSTEM.TITLE',
    message: 'COMMON.ERROR.SYSTEM.MESSAGE',
    image: 'assets/images/error.png',
    buttons: [
      {
        label: 'ERROR.BUTTON.TRY_AGAIN',
        color: 'primary',
      },
    ],
  },
  [ERROR_CODE.REGISTER]: {
    title: 'COMMON.ERROR.REGISTER.TITLE',
    message: 'COMMON.ERROR.REGISTER.MESSAGE',
    buttons: [
      {
        label: 'ERROR.BUTTON.TRY_AGAIN',
        color: 'primary',
        url: '/checkout/registered/intermission',
      },
      {
        label: 'CHECKOUT.START.BUTTON.SKIP_TO_CHECKOUT',
        url: '/checkout/guest/orderReview',
      },
    ],
  },
  [ERROR_CODE.USER_PORTAL_REGISTER]: {
    title: 'COMMON.ERROR.USER_PORTAL.REGISTER.TITLE',
    message: 'COMMON.ERROR.USER_PORTAL.REGISTER.MESSAGE',
    buttons: [
      {
        label: 'ERROR.BUTTON.TRY_AGAIN',
        color: 'primary',
        url: '/user-portal/createProfile/final',
      },
      {
        label: 'USER_PORTAL.START.BUTTON.CANCEL_REGISTRATION',
        url: '/user-portal/welcome',
      },
    ],
  },
  [ERROR_CODE.SHIPPING]: {
    title: 'COMMON.ERROR.SHIPPING.TITLE',
    message: 'COMMON.ERROR.SHIPPING.MESSAGE',
    buttons: [
      {
        label: 'ERROR.BUTTON.TRY_AGAIN',
        color: 'primary',
        callBack: () => {
          return window.location['reload']();
        },
      },
    ],
    overlayConfig: {
      hideCloseButton: true,
    },
  },
  [ERROR_CODE.SHIPPING_INVALID_REQUEST]: {
    title: 'COMMON.ERROR.SHIPPING_INVALID.TITLE',
    message: 'COMMON.ERROR.SHIPPING_INVALID.MESSAGE',
    buttons: [
      {
        label: 'COMMON.ERROR.SHIPPING_INVALID.BUTTON',
        color: 'primary',
      },
    ],
  },
  [ERROR_CODE.SHIPPING_ADD]: {
    title: 'COMMON.ERROR.SHIPPING_INVALID.TITLE',
    message: 'COMMON.ERROR.SHIPPING_ADD.MESSAGE',
    buttons: [
      {
        label: 'COMMON.ERROR.SHIPPING_INVALID.BUTTON',
        color: 'primary',
      },
    ],
  },
  [ERROR_CODE.SHIPPING_INVALID_POSTALCODE]: {
    title: 'COMMON.ERROR.SHIPPING_INVALID_POSTALCODE.TITLE',
    message: 'COMMON.ERROR.SHIPPING_INVALID_POSTALCODE.MESSAGE',
    buttons: [
      {
        label: 'COMMON.ERROR.SHIPPING_INVALID.BUTTON',
        color: 'primary',
      },
    ],
  },
  [ERROR_CODE.SHIPPING_DEFAULT]: {
    title: 'COMMON.ERROR.SHIPPING_DEFAULT.TITLE',
    message: 'COMMON.ERROR.SHIPPING_DEFAULT.MESSAGE',
    buttons: [
      {
        label: 'COMMON.ERROR.SHIPPING_INVALID.BUTTON',
        color: 'primary',
      },
    ],
    overlayConfig: {
      hideCloseButton: false,
    },
  },
  [ERROR_CODE.EMAIL_CONFIRMATION]: {
    title: 'STANDALONE.ERROR.EMAIL_CONFIRMATION.TITLE',
    message: 'STANDALONE.ERROR.EMAIL_CONFIRMATION.MESSAGE',
  },
  [ERROR_CODE.UPDATE_USER_PROFILE]: {
    title: 'USER_PORTAL.ERROR.UPDATE_USER_PROFILE.TITLE',
    message: 'USER_PORTAL.ERROR.UPDATE_USER_PROFILE.MESSAGE',
    buttons: [
      {
        label: 'ERROR.BUTTON.TRY_AGAIN',
        color: 'primary',
        url: '/user-portal/registered/manageProfile',
      },
    ],
  },
  [ERROR_CODE.GRANT_DENIED]: {
    title: 'CHECKOUT.ERROR.GRAND_DENIED.TITLE',
    message: 'CHECKOUT.ERROR.GRAND_DENIED.MESSAGE',
    image: 'COMMON.CHECKOUT.ERROR.CONSENT_DECLINED',
    buttons: [],
  },
  [ERROR_CODE.PAYMENT_FAILED]: {
    title: 'CHECKOUT.ERROR.GRAND_DENIED.TITLE',
    message: 'CHECKOUT.ERROR.GRAND_DENIED.MESSAGE',
    image: 'COMMON.CHECKOUT.ERROR.CONSENT_DECLINED',
    buttons: [],
  },
  [ERROR_CODE.CHECKOUT_EMAIL_REG_STATUS]: {
    title: 'COMMON.ERROR.REGISTER.TITLE',
    message: 'COMMON.ERROR.EMAIL_REG_STATUS.MESSAGE',
    buttons: [
      {
        label: 'ERROR.BUTTON.TRY_AGAIN',
        color: 'primary',
      },
      {
        label: 'CHECKOUT.START.BUTTON.SKIP_TO_CHECKOUT',
        url: '/checkout/guest/orderReview',
      },
    ],
  },
  [ERROR_CODE.USER_PORTAL_EMAIL_REG_STATUS]: {
    title: 'COMMON.ERROR.REGISTER.TITLE',
    message: 'COMMON.ERROR.EMAIL_REG_STATUS.MESSAGE',
    buttons: [
      {
        label: 'ERROR.BUTTON.TRY_AGAIN',
        color: 'primary',
      },
    ],
  },
  [ERROR_CODE.USER_PORTAL_COOKIE_DECLINE]: {
    title: 'COOKIE.PROMPT.ERROR.TITLE',
    message: 'COOKIE.PROMPT.ERROR.MESSAGE',
    buttons: [
      {
        label: 'COOKIE.PROMPT.MANAGE_SETTINGS',
        color: 'primary',
        url: 'user-portal/manage-cookies',
      },
    ],
  },
};

export const PBB_ERROR_CODE = {
  RESOURCE_NOT_FOUND: '4100',
  EMAIL_NOT_FOUND: '4104',
  TOO_MANY_REQUESTS: '4010',
};

export interface PbbHttpErrorResponse extends HttpErrorResponse {
  error: ModelError;
}

export interface IOIDC_ERROR {
  translateKey: string;
  noticeType: NOTICE_TYPE;
  selectedFI?: string;
}
export type OIDC_ERROR =
  | 'login_required'
  | 'consent_required'
  | 'profile_data_required'
  | 'profile_already_linked'
  | 'force_signout'
  | 'INTERNAL_SERVER_ERROR'
  | '4097'
  | '4098';

export const OIDC_ERROR: { [key: string]: IOIDC_ERROR } = {
  login_required: {
    translateKey: 'COMMON.ERROR.LOGIN_REQUIRED',
    noticeType: NOTICE_TYPE.ERROR,
  },
  consent_required: {
    translateKey: 'COMMON.ERROR.CONSENT_REQUIRED',
    noticeType: NOTICE_TYPE.ERROR,
  },
  profile_data_required: {
    translateKey: 'COMMON.ERROR.PROFILE_DATA_REQUIRED',
    noticeType: NOTICE_TYPE.ERROR,
  },
  profile_already_linked: {
    translateKey: 'COMMON.ERROR.PROFILE_ALREADY_LINKED',
    noticeType: NOTICE_TYPE.ERROR,
  },
  force_signout: {
    translateKey: 'COMMON.ERROR.FORCED_SIGNOUT',
    noticeType: NOTICE_TYPE.INFO,
  },
  INTERNAL_SERVER_ERROR: {
    translateKey: 'COMMON.ERROR.TRY_AGAIN',
    noticeType: NOTICE_TYPE.ERROR,
  },
  4097: {
    translateKey: 'COMMON.ERROR.4097',
    noticeType: NOTICE_TYPE.ERROR,
  },
  4098: {
    translateKey: 'COMMON.ERROR.4098',
    noticeType: NOTICE_TYPE.ERROR,
    selectedFI: 'selected bank',
  },
};

export type OIDC_CODE = '4110' | '4108' | '4112' | '4105' | '4115';

export const OIDC_CODE = {
  '4108': {
    checkout: {
      redirectPath: '/checkout/onboarding/start',
      noticeTranslateKey: 'OIDC.PROFILE_NOT_FOUND.NOTICE',
    } as OIDCDETAIL,
    userportal: {
      redirectPath: '/user-portal/onboarding/start',
      noticeTranslateKey: 'OIDC.PROFILE_NOT_FOUND.NOTICE',
    },
  },
  '4110': {
    checkout: {
      redirectPath: '/checkout/onboarding/start',
      noticeTranslateKey: 'OIDC.EMAIL_NOT_MATCH.NOTICE1',
      btnTranslateKey: 'OIDC.EMAIL_NOT_MATCH.BUTTON',
      signInLink: '/auth/signin',
    } as OIDCDETAIL,
    userportal: {
      redirectPath: '/user-portal/onboarding/start',
      noticeTranslateKey: 'OIDC.EMAIL_NOT_MATCH.NOTICE1',
      btnTranslateKey: 'OIDC.EMAIL_NOT_MATCH.BUTTON',
      signInLink: '/auth/signin',
    },
  },
  '4105': {
    checkout: {
      redirectPath: '/checkout/onboarding/start',
      noticeTranslateKey: 'OIDC.PROFILE_REF_NOT_FOUND.NOTICE',
    } as OIDCDETAIL,
    userportal: {
      redirectPath: '/user-portal/onboarding/start',
      noticeTranslateKey: 'OIDC.PROFILE_REF_NOT_FOUND.NOTICE',
    },
  },
  '4112': {
    checkout: {
      redirectPath: '/checkout/onboarding/start',
      noticeTranslateKey: 'OIDC.PROFILE_REF_NOT_MATCH.NOTICE',
      btnTranslateKey: 'OIDC.PROFILE_REF_NOT_MATCH.BUTTON',
      signInLink: '/auth/landing',
    } as OIDCDETAIL,
    userportal: {
      redirectPath: '/user-portal/onboarding/start',
      noticeTranslateKey: 'OIDC.PROFILE_REF_NOT_MATCH.NOTICE',
      btnTranslateKey: 'OIDC.PROFILE_REF_NOT_MATCH.BUTTON',
      signInLink: '/auth/landing',
    },
  },
  '4115': {
    checkout: {
      redirectPath: '/checkout/onboarding/already-linked',
      noticeTranslateKey: 'OIDC.FI_ALREADY_LINKED.NOTICE',
      btnTranslateKey: 'OIDC.PROFILE_REF_NOT_MATCH.BUTTON',
      signInLink: '/auth/landing',
    } as OIDCDETAIL,
    userportal: {
      redirectPath: '/user-portal/onboarding/already-linked',
      noticeTranslateKey: 'OIDC.FI_ALREADY_LINKED.NOTICE',
      btnTranslateKey: 'OIDC.PROFILE_REF_NOT_MATCH.BUTTON',
      signInLink: '/auth/landing',
    },
  },
};
export type STEPUP_ERROR_CODE = '4506' | '4507';
export const STEPUP_ERROR_CODE = {
  '4506': {
    noticeType: Notices.STEPUP_RETRY,
    error: false,
  } as STEPUPDETAIL,

  '4507': {
    errorCode: ERROR_CODE.STEPUP_FAIL,
    noticeType: Notices.STEPUP_FAIL,
    redirectToError: true,
  } as STEPUPDETAIL,
};
export interface OIDCDETAIL {
  redirectPath: string;
  noticeTranslateKey: string;
  btnTranslateKey?: string;
  signInLink?: string;
}
export interface STEPUPDETAIL {
  noticeType: Notices;

  errorCode?: ERROR_CODE;
  redirectToError?: boolean;
}

export const CREATE_PROFILE_CODES: string[] = ['4108', '4105'];
export const PROFILE_NOT_MATCH_CODES: string[] = ['4110', '4112'];
export const FI_ALREADY_LINKED_CODE: OIDC_CODE = '4115';

export enum PAYMENT_METHOD_CHECK_ERRORS {
  MERCHANT_SUPPORT_PAYMENT_METHOD = 'merchantNotSupportedAllPayments',
  MERCHANT_SUPPORT_CONSENT_PAYMENT_METHOD = 'merchantNotSupportConsentPayment',
  USER_PAYMENT_METHOD = 'userHasNoAvailablePayments',
  USER_DEFAULT_PAYMENT_METHOD = 'userDefaultPaymentIsRemoved',
  USER_CONSENT_PAYMENT_METHOD = 'userConsentPaymentIsRemoved',
}

export enum IDVSTATUS {
  IDV_SUCCESS = '8800',
  IDV_ERROR = '8801',
  IDV_INTERNAL_ERROR = '8802',
  IDV_HARD_STOP = '8803',
}
